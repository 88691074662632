const city = [{
  "name": "北京市",
  "value": "110000",
  "children": [{
    "name": "市辖区",
    "value": "110100",
    "parent": "110000"
  }]
}, {
  "name": "天津市",
  "value": "120000",
  "children": [{
    "name": "市辖区",
    "value": "120100",
    "parent": "120000"
  }]
}, {
  "name": "河北省",
  "value": "130000",
  "children": [{
    "name": "石家庄市",
    "value": "130100",
    "parent": "130000"
  }, {
    "name": "唐山市",
    "value": "130200",
    "parent": "130000"
  }, {
    "name": "秦皇岛市",
    "value": "130300",
    "parent": "130000"
  }, {
    "name": "邯郸市",
    "value": "130400",
    "parent": "130000",
    
  }, {
    "name": "邢台市",
    "value": "130500",
    "parent": "130000"
  }, {
    "name": "保定市",
    "value": "130600",
    "parent": "130000"
  }, {
    "name": "张家口市",
    "value": "130700",
    "parent": "130000"
  }, {
    "name": "承德市",
    "value": "130800",
    "parent": "130000"
  }, {
    "name": "沧州市",
    "value": "130900",
    "parent": "130000"
  }, {
    "name": "廊坊市",
    "value": "131000",
    "parent": "130000"
  }, {
    "name": "衡水市",
    "value": "131100",
    "parent": "130000"
  }]
}, {
  "name": "山西省",
  "value": "140000",
  "children": [{
    "name": "太原市",
    "value": "140100",
    "parent": "140000"
  }, {
    "name": "大同市",
    "value": "140200",
    "parent": "140000",
   
  }, {
    "name": "阳泉市",
    "value": "140300",
    "parent": "140000"
  }, {
    "name": "长治市",
    "value": "140400",
    "parent": "140000"
  }, {
    "name": "晋城市",
    "value": "140500",
    "parent": "140000"
  }, {
    "name": "朔州市",
    "value": "140600",
    "parent": "140000"
  }, {
    "name": "晋中市",
    "value": "140700",
    "parent": "140000"
  }, {
    "name": "运城市",
    "value": "140800",
    "parent": "140000"
  }, {
    "name": "忻州市",
    "value": "140900",
    "parent": "140000"
  }, {
    "name": "临汾市",
    "value": "141000",
    "parent": "140000"
  }, {
    "name": "吕梁市",
    "value": "141100",
    "parent": "140000"
  }]
}, {
  "name": "内蒙古自治区",
  "value": "150000",
  "children": [{
    "name": "呼和浩特市",
    "value": "150100",
    "parent": "150000"
  }, {
    "name": "包头市",
    "value": "150200",
    "parent": "150000"
  }, {
    "name": "乌海市",
    "value": "150300",
    "parent": "150000"
  }, {
    "name": "赤峰市",
    "value": "150400",
    "parent": "150000"
  }, {
    "name": "通辽市",
    "value": "150500",
    "parent": "150000"
  }, {
    "name": "鄂尔多斯市",
    "value": "150600",
    "parent": "150000"
  }, {
    "name": "呼伦贝尔市",
    "value": "150700",
    "parent": "150000"
  }, {
    "name": "巴彦淖尔市",
    "value": "150800",
    "parent": "150000"
  }, {
    "name": "乌兰察布市",
    "value": "150900",
    "parent": "150000"
  }, {
    "name": "兴安盟",
    "value": "152200",
    "parent": "150000"
  }, {
    "name": "锡林郭勒盟",
    "value": "152500",
    "parent": "150000"
  }, {
    "name": "阿拉善盟",
    "value": "152900",
    "parent": "150000"
  }]
}, {
  "name": "辽宁省",
  "value": "210000",
  "children": [{
    "name": "沈阳市",
    "value": "210100",
    "parent": "210000"
  }, {
    "name": "大连市",
    "value": "210200",
    "parent": "210000"
  }, {
    "name": "鞍山市",
    "value": "210300",
    "parent": "210000"
  }, {
    "name": "抚顺市",
    "value": "210400",
    "parent": "210000"
  }, {
    "name": "本溪市",
    "value": "210500",
    "parent": "210000"
  }, {
    "name": "丹东市",
    "value": "210600",
    "parent": "210000"
  }, {
    "name": "锦州市",
    "value": "210700",
    "parent": "210000"
  }, {
    "name": "营口市",
    "value": "210800",
    "parent": "210000"
  }, {
    "name": "阜新市",
    "value": "210900",
    "parent": "210000"
  }, {
    "name": "辽阳市",
    "value": "211000",
    "parent": "210000"
  }, {
    "name": "盘锦市",
    "value": "211100",
    "parent": "210000"
  }, {
    "name": "铁岭市",
    "value": "211200",
    "parent": "210000"
  }, {
    "name": "朝阳市",
    "value": "211300",
    "parent": "210000"
  }, {
    "name": "葫芦岛市",
    "value": "211400",
    "parent": "210000"
  }]
}, {
  "name": "吉林省",
  "value": "220000",
  "children": [{
    "name": "长春市",
    "value": "220100",
    "parent": "220000"
  }, {
    "name": "吉林市",
    "value": "220200",
    "parent": "220000"
  }, {
    "name": "四平市",
    "value": "220300",
    "parent": "220000"
  }, {
    "name": "辽源市",
    "value": "220400",
    "parent": "220000"
  }, {
    "name": "通化市",
    "value": "220500",
    "parent": "220000"
  }, {
    "name": "白山市",
    "value": "220600",
    "parent": "220000"
  }, {
    "name": "松原市",
    "value": "220700",
    "parent": "220000"
  }, {
    "name": "白城市",
    "value": "220800",
    "parent": "220000"
  }, {
    "name": "延边朝鲜族自治州",
    "value": "222400",
    "parent": "220000"
  }]
}, {
  "name": "黑龙江省",
  "value": "230000",
  "children": [{
    "name": "哈尔滨市",
    "value": "230100",
    "parent": "230000"
  }, {
    "name": "齐齐哈尔市",
    "value": "230200",
    "parent": "230000"
  }, {
    "name": "鸡西市",
    "value": "230300",
    "parent": "230000"
  }, {
    "name": "鹤岗市",
    "value": "230400",
    "parent": "230000"
  }, {
    "name": "双鸭山市",
    "value": "230500",
    "parent": "230000"
  }, {
    "name": "大庆市",
    "value": "230600",
    "parent": "230000"
  }, {
    "name": "伊春市",
    "value": "230700",
    "parent": "230000"
  }, {
    "name": "佳木斯市",
    "value": "230800",
    "parent": "230000"
  }, {
    "name": "七台河市",
    "value": "230900",
    "parent": "230000"
  }, {
    "name": "牡丹江市",
    "value": "231000",
    "parent": "230000"
  }, {
    "name": "黑河市",
    "value": "231100",
    "parent": "230000"
  }, {
    "name": "绥化市",
    "value": "231200",
    "parent": "230000"
  }, {
    "name": "大兴安岭地区",
    "value": "232700",
    "parent": "230000"
  }]
}, {
  "name": "上海市",
  "value": "310000",
  "children": [{
    "name": "市辖区",
    "value": "310100",
    "parent": "310000"
  }]
}, {
  "name": "江苏省",
  "value": "320000",
  "children": [{
    "name": "南京市",
    "value": "320100",
    "parent": "320000"
  }, {
    "name": "无锡市",
    "value": "320200",
    "parent": "320000"
  }, {
    "name": "徐州市",
    "value": "320300",
    "parent": "320000"
  }, {
    "name": "常州市",
    "value": "320400",
    "parent": "320000"
  }, {
    "name": "苏州市",
    "value": "320500",
    "parent": "320000"
  }, {
    "name": "南通市",
    "value": "320600",
    "parent": "320000"
  }, {
    "name": "连云港市",
    "value": "320700",
    "parent": "320000"
  }, {
    "name": "淮安市",
    "value": "320800",
    "parent": "320000"
  }, {
    "name": "盐城市",
    "value": "320900",
    "parent": "320000"
  }, {
    "name": "扬州市",
    "value": "321000",
    "parent": "320000"
  }, {
    "name": "镇江市",
    "value": "321100",
    "parent": "320000"
  }, {
    "name": "泰州市",
    "value": "321200",
    "parent": "320000"
  }, {
    "name": "宿迁市",
    "value": "321300",
    "parent": "320000"
  }]
}, {
  "name": "浙江省",
  "value": "330000",
  "children": [{
    "name": "杭州市",
    "value": "330100",
    "parent": "330000"
  }, {
    "name": "宁波市",
    "value": "330200",
    "parent": "330000"
  }, {
    "name": "温州市",
    "value": "330300",
    "parent": "330000"
  }, {
    "name": "嘉兴市",
    "value": "330400",
    "parent": "330000"
  }, {
    "name": "湖州市",
    "value": "330500",
    "parent": "330000"
  }, {
    "name": "绍兴市",
    "value": "330600",
    "parent": "330000"
  }, {
    "name": "金华市",
    "value": "330700",
    "parent": "330000"
  }, {
    "name": "衢州市",
    "value": "330800",
    "parent": "330000"
  }, {
    "name": "舟山市",
    "value": "330900",
    "parent": "330000"
  }, {
    "name": "台州市",
    "value": "331000",
    "parent": "330000"
  }, {
    "name": "丽水市",
    "value": "331100",
    "parent": "330000"
  }]
}, {
  "name": "安徽省",
  "value": "340000",
  "children": [{
    "name": "合肥市",
    "value": "340100",
    "parent": "340000"
  }, {
    "name": "芜湖市",
    "value": "340200",
    "parent": "340000"
  }, {
    "name": "蚌埠市",
    "value": "340300",
    "parent": "340000"
  }, {
    "name": "淮南市",
    "value": "340400",
    "parent": "340000"
  }, {
    "name": "马鞍山市",
    "value": "340500",
    "parent": "340000"
  }, {
    "name": "淮北市",
    "value": "340600",
    "parent": "340000"
  }, {
    "name": "铜陵市",
    "value": "340700",
    "parent": "340000"
  }, {
    "name": "安庆市",
    "value": "340800",
    "parent": "340000"
  }, {
    "name": "黄山市",
    "value": "341000",
    "parent": "340000"
  }, {
    "name": "滁州市",
    "value": "341100",
    "parent": "340000"
  }, {
    "name": "阜阳市",
    "value": "341200",
    "parent": "340000"
  }, {
    "name": "宿州市",
    "value": "341300",
    "parent": "340000"
  }, {
    "name": "六安市",
    "value": "341500",
    "parent": "340000"
  }, {
    "name": "亳州市",
    "value": "341600",
    "parent": "340000"
  }, {
    "name": "池州市",
    "value": "341700",
    "parent": "340000"
  }, {
    "name": "宣城市",
    "value": "341800",
    "parent": "340000"
  }]
}, {
  "name": "福建省",
  "value": "350000",
  "children": [{
    "name": "福州市",
    "value": "350100",
    "parent": "350000"
  }, {
    "name": "厦门市",
    "value": "350200",
    "parent": "350000"
  }, {
    "name": "莆田市",
    "value": "350300",
    "parent": "350000"
  }, {
    "name": "三明市",
    "value": "350400",
    "parent": "350000"
  }, {
    "name": "泉州市",
    "value": "350500",
    "parent": "350000"
  }, {
    "name": "漳州市",
    "value": "350600",
    "parent": "350000"
  }, {
    "name": "南平市",
    "value": "350700",
    "parent": "350000"
  }, {
    "name": "龙岩市",
    "value": "350800",
    "parent": "350000"
  }, {
    "name": "宁德市",
    "value": "350900",
    "parent": "350000"
  }]
}, {
  "name": "江西省",
  "value": "360000",
  "children": [{
    "name": "南昌市",
    "value": "360100",
    "parent": "360000"
  }, {
    "name": "景德镇市",
    "value": "360200",
    "parent": "360000"
  }, {
    "name": "萍乡市",
    "value": "360300",
    "parent": "360000"
  }, {
    "name": "九江市",
    "value": "360400",
    "parent": "360000"
  }, {
    "name": "新余市",
    "value": "360500",
    "parent": "360000"
  }, {
    "name": "鹰潭市",
    "value": "360600",
    "parent": "360000"
  }, {
    "name": "赣州市",
    "value": "360700",
    "parent": "360000"
  }, {
    "name": "吉安市",
    "value": "360800",
    "parent": "360000"
  }, {
    "name": "宜春市",
    "value": "360900",
    "parent": "360000"
  }, {
    "name": "抚州市",
    "value": "361000",
    "parent": "360000"
  }, {
    "name": "上饶市",
    "value": "361100",
    "parent": "360000"
  }]
}, {
  "name": "山东省",
  "value": "370000",
  "children": [{
    "name": "济南市",
    "value": "370100",
    "parent": "370000"
  }, {
    "name": "青岛市",
    "value": "370200",
    "parent": "370000"
  }, {
    "name": "淄博市",
    "value": "370300",
    "parent": "370000"
  }, {
    "name": "枣庄市",
    "value": "370400",
    "parent": "370000"
  }, {
    "name": "东营市",
    "value": "370500",
    "parent": "370000"
  }, {
    "name": "烟台市",
    "value": "370600",
    "parent": "370000"
  }, {
    "name": "潍坊市",
    "value": "370700",
    "parent": "370000"
  }, {
    "name": "济宁市",
    "value": "370800",
    "parent": "370000"
  }, {
    "name": "泰安市",
    "value": "370900",
    "parent": "370000"
  }, {
    "name": "威海市",
    "value": "371000",
    "parent": "370000"
  }, {
    "name": "日照市",
    "value": "371100",
    "parent": "370000"
  }, {
    "name": "临沂市",
    "value": "371300",
    "parent": "370000"
  }, {
    "name": "德州市",
    "value": "371400",
    "parent": "370000"
  }, {
    "name": "聊城市",
    "value": "371500",
    "parent": "370000"
  }, {
    "name": "滨州市",
    "value": "371600",
    "parent": "370000"
  }, {
    "name": "菏泽市",
    "value": "371700",
    "parent": "370000"
  }]
}, {
  "name": "河南省",
  "value": "410000",
  "children": [{
    "name": "郑州市",
    "value": "410100",
    "parent": "410000"
  }, {
    "name": "开封市",
    "value": "410200",
    "parent": "410000"
  }, {
    "name": "洛阳市",
    "value": "410300",
    "parent": "410000"
  }, {
    "name": "平顶山市",
    "value": "410400",
    "parent": "410000"
  }, {
    "name": "安阳市",
    "value": "410500",
    "parent": "410000"
  }, {
    "name": "鹤壁市",
    "value": "410600",
    "parent": "410000"
  }, {
    "name": "新乡市",
    "value": "410700",
    "parent": "410000"
  }, {
    "name": "焦作市",
    "value": "410800",
    "parent": "410000"
  }, {
    "name": "濮阳市",
    "value": "410900",
    "parent": "410000"
  }, {
    "name": "许昌市",
    "value": "411000",
    "parent": "410000"
  }, {
    "name": "漯河市",
    "value": "411100",
    "parent": "410000"
  }, {
    "name": "三门峡市",
    "value": "411200",
    "parent": "410000"
  }, {
    "name": "南阳市",
    "value": "411300",
    "parent": "410000"
  }, {
    "name": "商丘市",
    "value": "411400",
    "parent": "410000"
  }, {
    "name": "信阳市",
    "value": "411500",
    "parent": "410000"
  }, {
    "name": "周口市",
    "value": "411600",
    "parent": "410000"
  }, {
    "name": "驻马店市",
    "value": "411700",
    "parent": "410000"
  }, {
    "name": "省直辖县级行政区划",
    "value": "419000",
    "parent": "410000"
  }]
}, {
  "name": "湖北省",
  "value": "420000",
  "children": [{
    "name": "武汉市",
    "value": "420100",
    "parent": "420000"
  }, {
    "name": "黄石市",
    "value": "420200",
    "parent": "420000"
  }, {
    "name": "十堰市",
    "value": "420300",
    "parent": "420000"
  }, {
    "name": "宜昌市",
    "value": "420500",
    "parent": "420000"
  }, {
    "name": "襄阳市",
    "value": "420600",
    "parent": "420000"
  }, {
    "name": "鄂州市",
    "value": "420700",
    "parent": "420000"
  }, {
    "name": "荆门市",
    "value": "420800",
    "parent": "420000"
  }, {
    "name": "孝感市",
    "value": "420900",
    "parent": "420000"
  }, {
    "name": "荆州市",
    "value": "421000",
    "parent": "420000"
  }, {
    "name": "黄冈市",
    "value": "421100",
    "parent": "420000"
  }, {
    "name": "咸宁市",
    "value": "421200",
    "parent": "420000"
  }, {
    "name": "随州市",
    "value": "421300",
    "parent": "420000"
  }, {
    "name": "恩施土家族苗族自治州",
    "value": "422800",
    "parent": "420000"
  }, {
    "name": "省直辖县级行政区划",
    "value": "429000",
    "parent": "420000"
  }]
}, {
  "name": "湖南省",
  "value": "430000",
  "children": [{
    "name": "长沙市",
    "value": "430100",
    "parent": "430000"
  }, {
    "name": "株洲市",
    "value": "430200",
    "parent": "430000"
  }, {
    "name": "湘潭市",
    "value": "430300",
    "parent": "430000"
  }, {
    "name": "衡阳市",
    "value": "430400",
    "parent": "430000"
  }, {
    "name": "邵阳市",
    "value": "430500",
    "parent": "430000"
  }, {
    "name": "岳阳市",
    "value": "430600",
    "parent": "430000"
  }, {
    "name": "常德市",
    "value": "430700",
    "parent": "430000"
  }, {
    "name": "张家界市",
    "value": "430800",
    "parent": "430000"
  }, {
    "name": "益阳市",
    "value": "430900",
    "parent": "430000"
  }, {
    "name": "郴州市",
    "value": "431000",
    "parent": "430000"
  }, {
    "name": "永州市",
    "value": "431100",
    "parent": "430000"
  }, {
    "name": "怀化市",
    "value": "431200",
    "parent": "430000"
  }, {
    "name": "娄底市",
    "value": "431300",
    "parent": "430000"
  }, {
    "name": "湘西土家族苗族自治州",
    "value": "433100",
    "parent": "430000"
  }]
}, {
  "name": "广东省",
  "value": "440000",
  "children": [{
    "name": "广州市",
    "value": "440100",
    "parent": "440000"
  }, {
    "name": "韶关市",
    "value": "440200",
    "parent": "440000"
  }, {
    "name": "深圳市",
    "value": "440300",
    "parent": "440000"
  }, {
    "name": "珠海市",
    "value": "440400",
    "parent": "440000"
  }, {
    "name": "汕头市",
    "value": "440500",
    "parent": "440000"
  }, {
    "name": "佛山市",
    "value": "440600",
    "parent": "440000"
  }, {
    "name": "江门市",
    "value": "440700",
    "parent": "440000"
  }, {
    "name": "湛江市",
    "value": "440800",
    "parent": "440000"
  }, {
    "name": "茂名市",
    "value": "440900",
    "parent": "440000"
  }, {
    "name": "肇庆市",
    "value": "441200",
    "parent": "440000"
  }, {
    "name": "惠州市",
    "value": "441300",
    "parent": "440000"
  }, {
    "name": "梅州市",
    "value": "441400",
    "parent": "440000"
  }, {
    "name": "汕尾市",
    "value": "441500",
    "parent": "440000"
  }, {
    "name": "河源市",
    "value": "441600",
    "parent": "440000"
  }, {
    "name": "阳江市",
    "value": "441700",
    "parent": "440000"
  }, {
    "name": "清远市",
    "value": "441800",
    "parent": "440000"
  }, {
    "name": "东莞市",
    "value": "441900",
    "parent": "440000"
  }, {
    "name": "中山市",
    "value": "442000",
    "parent": "440000"
  }, {
    "name": "潮州市",
    "value": "445100",
    "parent": "440000"
  }, {
    "name": "揭阳市",
    "value": "445200",
    "parent": "440000"
  }, {
    "name": "云浮市",
    "value": "445300",
    "parent": "440000"
  }]
}, {
  "name": "广西壮族自治区",
  "value": "450000",
  "children": [{
    "name": "南宁市",
    "value": "450100",
    "parent": "450000"
  }, {
    "name": "柳州市",
    "value": "450200",
    "parent": "450000"
  }, {
    "name": "桂林市",
    "value": "450300",
    "parent": "450000"
  }, {
    "name": "梧州市",
    "value": "450400",
    "parent": "450000"
  }, {
    "name": "北海市",
    "value": "450500",
    "parent": "450000"
  }, {
    "name": "防城港市",
    "value": "450600",
    "parent": "450000"
  }, {
    "name": "钦州市",
    "value": "450700",
    "parent": "450000"
  }, {
    "name": "贵港市",
    "value": "450800",
    "parent": "450000"
  }, {
    "name": "玉林市",
    "value": "450900",
    "parent": "450000"
  }, {
    "name": "百色市",
    "value": "451000",
    "parent": "450000"
  }, {
    "name": "贺州市",
    "value": "451100",
    "parent": "450000"
  }, {
    "name": "河池市",
    "value": "451200",
    "parent": "450000"
  }, {
    "name": "来宾市",
    "value": "451300",
    "parent": "450000"
  }, {
    "name": "崇左市",
    "value": "451400",
    "parent": "450000"
  }]
}, {
  "name": "海南省",
  "value": "460000",
  "children": [{
    "name": "海口市",
    "value": "460100",
    "parent": "460000"
  }, {
    "name": "三亚市",
    "value": "460200",
    "parent": "460000"
  }, {
    "name": "三沙市",
    "value": "460300",
    "parent": "460000"
  }, {
    "name": "儋州市",
    "value": "460400",
    "parent": "460000"
  }, {
    "name": "省直辖县级行政区划",
    "value": "469000",
    "parent": "460000"
  }]
}, {
  "name": "重庆市",
  "value": "500000",
  "children": [{
    "name": "市辖区",
    "value": "500100",
    "parent": "500000"
  }]
}, {
  "name": "四川省",
  "value": "510000",
  "children": [{
    "name": "成都市",
    "value": "510100",
    "parent": "510000"
  }, {
    "name": "自贡市",
    "value": "510300",
    "parent": "510000"
  }, {
    "name": "攀枝花市",
    "value": "510400",
    "parent": "510000"
  }, {
    "name": "泸州市",
    "value": "510500",
    "parent": "510000"
  }, {
    "name": "德阳市",
    "value": "510600",
    "parent": "510000"
  }, {
    "name": "绵阳市",
    "value": "510700",
    "parent": "510000"
  }, {
    "name": "广元市",
    "value": "510800",
    "parent": "510000"
  }, {
    "name": "遂宁市",
    "value": "510900",
    "parent": "510000"
  }, {
    "name": "内江市",
    "value": "511000",
    "parent": "510000"
  }, {
    "name": "乐山市",
    "value": "511100",
    "parent": "510000"
  }, {
    "name": "南充市",
    "value": "511300",
    "parent": "510000"
  }, {
    "name": "眉山市",
    "value": "511400",
    "parent": "510000"
  }, {
    "name": "宜宾市",
    "value": "511500",
    "parent": "510000"
  }, {
    "name": "广安市",
    "value": "511600",
    "parent": "510000"
  }, {
    "name": "达州市",
    "value": "511700",
    "parent": "510000"
  }, {
    "name": "雅安市",
    "value": "511800",
    "parent": "510000"
  }, {
    "name": "巴中市",
    "value": "511900",
    "parent": "510000"
  }, {
    "name": "资阳市",
    "value": "512000",
    "parent": "510000"
  }, {
    "name": "阿坝藏族羌族自治州",
    "value": "513200",
    "parent": "510000"
  }, {
    "name": "甘孜藏族自治州",
    "value": "513300",
    "parent": "510000"
  }, {
    "name": "凉山彝族自治州",
    "value": "513400",
    "parent": "510000"
  }]
}, {
  "name": "贵州省",
  "value": "520000",
  "children": [{
    "name": "贵阳市",
    "value": "520100",
    "parent": "520000"
  }, {
    "name": "六盘水市",
    "value": "520200",
    "parent": "520000"
  }, {
    "name": "遵义市",
    "value": "520300",
    "parent": "520000"
  }, {
    "name": "安顺市",
    "value": "520400",
    "parent": "520000"
  }, {
    "name": "毕节市",
    "value": "520500",
    "parent": "520000"
  }, {
    "name": "铜仁市",
    "value": "520600",
    "parent": "520000"
  }, {
    "name": "黔西南布依族苗族自治州",
    "value": "522300",
    "parent": "520000"
  }, {
    "name": "黔东南苗族侗族自治州",
    "value": "522600",
    "parent": "520000"
  }, {
    "name": "黔南布依族苗族自治州",
    "value": "522700",
    "parent": "520000"
  }]
}, {
  "name": "云南省",
  "value": "530000",
  "children": [{
    "name": "昆明市",
    "value": "530100",
    "parent": "530000"
  }, {
    "name": "曲靖市",
    "value": "530300",
    "parent": "530000"
  }, {
    "name": "玉溪市",
    "value": "530400",
    "parent": "530000"
  }, {
    "name": "保山市",
    "value": "530500",
    "parent": "530000"
  }, {
    "name": "昭通市",
    "value": "530600",
    "parent": "530000"
  }, {
    "name": "丽江市",
    "value": "530700",
    "parent": "530000"
  }, {
    "name": "普洱市",
    "value": "530800",
    "parent": "530000"
  }, {
    "name": "临沧市",
    "value": "530900",
    "parent": "530000"
  }, {
    "name": "楚雄彝族自治州",
    "value": "532300",
    "parent": "530000"
  }, {
    "name": "红河哈尼族彝族自治州",
    "value": "532500",
    "parent": "530000"
  }, {
    "name": "文山壮族苗族自治州",
    "value": "532600",
    "parent": "530000"
  }, {
    "name": "西双版纳傣族自治州",
    "value": "532800",
    "parent": "530000"
  }, {
    "name": "大理白族自治州",
    "value": "532900",
    "parent": "530000"
  }, {
    "name": "德宏傣族景颇族自治州",
    "value": "533100",
    "parent": "530000"
  }, {
    "name": "怒江傈僳族自治州",
    "value": "533300",
    "parent": "530000"
  }, {
    "name": "迪庆藏族自治州",
    "value": "533400",
    "parent": "530000"
  }]
}, {
  "name": "西藏自治区",
  "value": "540000",
  "children": [{
    "name": "拉萨市",
    "value": "540100",
    "parent": "540000"
  }, {
    "name": "日喀则市",
    "value": "540200",
    "parent": "540000"
  }, {
    "name": "昌都市",
    "value": "540300",
    "parent": "540000"
  }, {
    "name": "林芝市",
    "value": "540400",
    "parent": "540000"
  }, {
    "name": "山南市",
    "value": "540500",
    "parent": "540000"
  }, {
    "name": "那曲市",
    "value": "540600",
    "parent": "540000"
  }, {
    "name": "阿里地区",
    "value": "542500",
    "parent": "540000"
  }]
}, {
  "name": "陕西省",
  "value": "610000",
  "children": [{
    "name": "西安市",
    "value": "610100",
    "parent": "610000"
  }, {
    "name": "铜川市",
    "value": "610200",
    "parent": "610000"
  }, {
    "name": "宝鸡市",
    "value": "610300",
    "parent": "610000"
  }, {
    "name": "咸阳市",
    "value": "610400",
    "parent": "610000"
  }, {
    "name": "渭南市",
    "value": "610500",
    "parent": "610000"
  }, {
    "name": "延安市",
    "value": "610600",
    "parent": "610000"
  }, {
    "name": "汉中市",
    "value": "610700",
    "parent": "610000"
  }, {
    "name": "榆林市",
    "value": "610800",
    "parent": "610000"
  }, {
    "name": "安康市",
    "value": "610900",
    "parent": "610000"
  }, {
    "name": "商洛市",
    "value": "611000",
    "parent": "610000"
  }]
}, {
  "name": "甘肃省",
  "value": "620000",
  "children": [{
    "name": "兰州市",
    "value": "620100",
    "parent": "620000"
  }, {
    "name": "嘉峪关市",
    "value": "620200",
    "parent": "620000"
  }, {
    "name": "金昌市",
    "value": "620300",
    "parent": "620000"
  }, {
    "name": "白银市",
    "value": "620400",
    "parent": "620000"
  }, {
    "name": "天水市",
    "value": "620500",
    "parent": "620000"
  }, {
    "name": "武威市",
    "value": "620600",
    "parent": "620000"
  }, {
    "name": "张掖市",
    "value": "620700",
    "parent": "620000"
  }, {
    "name": "平凉市",
    "value": "620800",
    "parent": "620000"
  }, {
    "name": "酒泉市",
    "value": "620900",
    "parent": "620000"
  }, {
    "name": "庆阳市",
    "value": "621000",
    "parent": "620000"
  }, {
    "name": "定西市",
    "value": "621100",
    "parent": "620000"
  }, {
    "name": "陇南市",
    "value": "621200",
    "parent": "620000"
  }, {
    "name": "临夏回族自治州",
    "value": "622900",
    "parent": "620000"
  }, {
    "name": "甘南藏族自治州",
    "value": "623000",
    "parent": "620000"
  }]
}, {
  "name": "青海省",
  "value": "630000",
  "children": [{
    "name": "西宁市",
    "value": "630100",
    "parent": "630000"
  }, {
    "name": "海东市",
    "value": "630200",
    "parent": "630000"
  }, {
    "name": "海北藏族自治州",
    "value": "632200",
    "parent": "630000"
  }, {
    "name": "黄南藏族自治州",
    "value": "632300",
    "parent": "630000"
  }, {
    "name": "海南藏族自治州",
    "value": "632500",
    "parent": "630000"
  }, {
    "name": "果洛藏族自治州",
    "value": "632600",
    "parent": "630000"
  }, {
    "name": "玉树藏族自治州",
    "value": "632700",
    "parent": "630000"
  }, {
    "name": "海西蒙古族藏族自治州",
    "value": "632800",
    "parent": "630000"
  }]
}, {
  "name": "宁夏回族自治区",
  "value": "640000",
  "children": [{
    "name": "银川市",
    "value": "640100",
    "parent": "640000"
  }, {
    "name": "石嘴山市",
    "value": "640200",
    "parent": "640000"
  }, {
    "name": "吴忠市",
    "value": "640300",
    "parent": "640000"
  }, {
    "name": "固原市",
    "value": "640400",
    "parent": "640000"
  }, {
    "name": "中卫市",
    "value": "640500",
    "parent": "640000"
  }]
}, {
  "name": "新疆维吾尔自治区",
  "value": "650000",
  "children": [{
    "name": "乌鲁木齐市",
    "value": "650100",
    "parent": "650000"
  }, {
    "name": "克拉玛依市",
    "value": "650200",
    "parent": "650000"
  }, {
    "name": "吐鲁番市",
    "value": "650400",
    "parent": "650000"
  }, {
    "name": "哈密市",
    "value": "650500",
    "parent": "650000"
  }, {
    "name": "昌吉回族自治州",
    "value": "652300",
    "parent": "650000"
  }, {
    "name": "博尔塔拉蒙古自治州",
    "value": "652700",
    "parent": "650000"
  }, {
    "name": "巴音郭楞蒙古自治州",
    "value": "652800",
    "parent": "650000"
  }, {
    "name": "阿克苏地区",
    "value": "652900",
    "parent": "650000"
  }, {
    "name": "克孜勒苏柯尔克孜自治州",
    "value": "653000",
    "parent": "650000"
  }, {
    "name": "喀什地区",
    "value": "653100",
    "parent": "650000"
  }, {
    "name": "和田地区",
    "value": "653200",
    "parent": "650000"
  }, {
    "name": "伊犁哈萨克自治州",
    "value": "654000",
    "parent": "650000"
  }, {
    "name": "塔城地区",
    "value": "654200",
    "parent": "650000"
  }, {
    "name": "阿勒泰地区",
    "value": "654300",
    "parent": "650000"
  }, {
    "name": "自治区直辖县级行政区划",
    "value": "659000",
    "parent": "650000"
  }]
}, {
  "name": "台湾省",
  "value": "710000",
  "children": [{
    "name": "台北市",
    "value": "710100",
    "parent": "710000"
  }, {
    "name": "高雄市",
    "value": "710200",
    "parent": "710000"
  }, {
    "name": "基隆市",
    "value": "710300",
    "parent": "710000"
  }, {
    "name": "台中市",
    "value": "710400",
    "parent": "710000"
  }, {
    "name": "台南市",
    "value": "710500",
    "parent": "710000"
  }, {
    "name": "新竹市",
    "value": "710600",
    "parent": "710000"
  }, {
    "name": "嘉义市",
    "value": "710700",
    "parent": "710000"
  }]
}, {
  "name": "香港特别行政区",
  "value": "810000",
  "children": [{
    "name": "中西區",
    "value": "810001",
    "parent": "810000"
  }, {
    "name": "灣仔區",
    "value": "810002",
    "parent": "810000"
  }, {
    "name": "東區",
    "value": "810003",
    "parent": "810000"
  }, {
    "name": "南區",
    "value": "810004",
    "parent": "810000"
  }, {
    "name": "油尖旺區",
    "value": "810005",
    "parent": "810000"
  }, {
    "name": "深水埗區",
    "value": "810006",
    "parent": "810000"
  }, {
    "name": "九龍城區",
    "value": "810007",
    "parent": "810000"
  }, {
    "name": "黃大仙區",
    "value": "810008",
    "parent": "810000"
  }, {
    "name": "觀塘區",
    "value": "810009",
    "parent": "810000"
  }, {
    "name": "荃灣區",
    "value": "810010",
    "parent": "810000"
  }, {
    "name": "屯門區",
    "value": "810011",
    "parent": "810000"
  }, {
    "name": "元朗區",
    "value": "810012",
    "parent": "810000"
  }, {
    "name": "北區",
    "value": "810013",
    "parent": "810000"
  }, {
    "name": "大埔區",
    "value": "810014",
    "parent": "810000"
  }, {
    "name": "西貢區",
    "value": "810015",
    "parent": "810000"
  }, {
    "name": "沙田區",
    "value": "810016",
    "parent": "810000"
  }, {
    "name": "葵青區",
    "value": "810017",
    "parent": "810000"
  }, {
    "name": "離島區",
    "value": "810018",
    "parent": "810000"
  }]
}, {
  "name": "澳门特别行政区",
  "value": "820000",
  "children": [{
    "name": "花地瑪堂區",
    "value": "820001",
    "parent": "820000"
  }, {
    "name": "花王堂區",
    "value": "820002",
    "parent": "820000"
  }, {
    "name": "望德堂區",
    "value": "820003",
    "parent": "820000"
  }, {
    "name": "大堂區",
    "value": "820004",
    "parent": "820000"
  }, {
    "name": "風順堂區",
    "value": "820005",
    "parent": "820000"
  }, {
    "name": "嘉模堂區",
    "value": "820006",
    "parent": "820000"
  }, {
    "name": "路氹填海區",
    "value": "820007",
    "parent": "820000"
  }, {
    "name": "聖方濟各堂區",
    "value": "820008",
    "parent": "820000"
  }]
}]
export default city;
